<template>
  <div class="nav" :class="{ nav_shadow: shadow_show }">
    <div class="nav_content">
      <div class="nav_log">
        <img :src="login_icon" alt="logo" />
        <span>域善</span>
      </div>
      <div class="nav_flex" @click="nav_show = !nav_show">
        <img :src="require('../assets/img/index/菜单.png')" alt="菜单" />
      </div>
      <div class="nav_route" :class="{ route_flex: nav_show }">
        <div class="title" v-for="(a, b) in title" :key="b" @click="route(b)">
          <router-link :to="a.link">{{ a.title }}</router-link>
        </div>
        <div class="slider" :style="{ left: 27 + 100 * index + 'px' }"></div>
      </div>
      <div class="nav_btn_list">
        <div class="nav_btn text_btn" @click="login">登录</div>
<!--        <div class="nav_btn dark_btn" @click="login">注册</div>-->
      </div>
    </div>
  </div>
</template>

<script>
import login_icon from '../assets/img/logo_icon.jpg';
export default {
  data() {
    return {
      index: 0,
      login_icon,
      nav_show: false,
      shadow_show: false,
      title: [
        { title: '首页', link: '/index' },
        { title: '版本', link: '/price' },
        { title: '案例', link: '/example' },
        { title: '伙伴', link: '/friend' },
        { title: '关于', link: '/about' },
      ],
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    this.title.forEach((a, b) => {
      if (a.link === this.$route.path) this.index = b;
    });
  },
  methods: {
    handleScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      this.shadow_show = scrollTop > 0 ? true : false;
    },
    route(i) {
      this.index = i;
      this.nav_show = false;
    },
    login() {
      // window.open('https://test-ext.yushanyun.net/horn/login');
      window.open('https://login.yushanyun.net');
    },
  },
  components: {},
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 1200px) {
  .nav {
    height: 90px;
    background: white;
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    z-index: 12;
    transition: 0.3s;
    &.nav_shadow {
      height: 50px;
      opacity: 0.95;
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    }
    .nav_content {
      height: 100%;
      display: flex;
      align-items: center;
      position: relative;
    }
    .nav_log {
      display: flex;
      align-self: center;
      margin-left: 40px;
      img {
        height: 40px;
        width: 40px;
        margin-right: 20px;
      }
      span {
        font-size: 18px;
        line-height: 40px;
        font-weight: bold;
        color: #073d7d;
      }
    }
    .nav_flex {
      display: none;
    }
    .nav_route {
      margin-left: 140px;
      position: relative;
      .slider {
        position: absolute;
        width: 24px;
        height: 3px;
        background: #ff6357;
        bottom: 0;
        border-radius: 2px;
        box-shadow: 0px 3px 5px 0px #ff635778;
        transition: 0.3s;
      }
      .title {
        line-height: 40px;
        display: inline-block;
        width: 80px;
        font-size: 14px;
        text-align: center;
        margin-right: 20px;
        position: relative;
        a {
          text-decoration: none;
          color: #6782a4;
          display: inline-block;
          width: 100%;
          height: 100%;
          transition: 0.3s;
        }
        .router-link-active {
          font-size: 16px;
          color: #073d7d;
        }
      }
    }
    .nav_btn_list {
      position: absolute;
      right: 20px;
      display: flex;
      .nav_btn {
        width: 100px;
        text-align: center;
        line-height: 32px;
        border-radius: 2px;
        margin-right: 5px;
        font-size: 14px;
        &:hover {
          cursor: pointer;
        }
      }
      .text_btn {
        color: #6782a4;
      }
      .dark_btn {
        color: white;
        background: #073d7d;
        box-shadow: 0px 5px 15px 0px rgba(25, 27, 200, 0.2);
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .nav {
    height: 1rem;
    background: white;
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    z-index: 12;
    transition: 0.3s;
    &.nav_shadow {
      opacity: 0.95;
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    }
    .nav_content {
      height: 100%;
      display: flex;
      align-items: center;
      position: relative;
    }
    .nav_log {
      display: flex;
      align-self: center;
      margin-left: 0.4rem;
      img {
        height: 0.4rem;
        width: 0.4rem;
        margin-right: 0.2rem;
      }
      span {
        font-size: 0.32rem;
        line-height: 0.44rem;
        font-weight: bold;
        color: #073d7d;
      }
    }
    .nav_flex {
      position: absolute;
      right: 0.2rem;
      top: 0.32rem;
      .iconfont {
        font-size: 0.4rem;
      }
      img {
        width: 0.4rem;
      }
    }
    .nav_route {
      position: absolute;
      top: 1rem;
      width: 100%;
      height: 0;
      overflow: hidden;
      background: white;
      opacity: 0.95;
      transition: 0.3s;
      .title {
        line-height: 0.8rem;
        display: inline-block;
        width: 100%;
        font-size: 0.28rem;
        text-align: left;
        margin-right: 0.2rem;
        position: relative;
        border-bottom: 1px solid #f4f4f5;
        a {
          padding-left: 0.2rem;
          box-sizing: border-box;
          text-decoration: none;
          color: #6782a4;
          display: inline-block;
          width: 100%;
          height: 100%;
          transition: 0.3s;
        }
        .router-link-active {
          font-size: 0.32rem;
          color: #073d7d;
          position: relative;
          &::before {
            content: '';
            position: absolute;
            width: 0.03rem;
            height: 0.4rem;
            background: #ff6357;
            top: 0.24rem;
            right: 0.2rem;
            border-radius: 2px;
            box-shadow: 3px 0px 5px 0px #ff635778;
          }
        }
      }
    }
    .route_flex {
      height: 4.9rem;
    }
    .nav_btn_list {
      display: none;
      position: absolute;
      right: 20px;
      // display: flex;
      .nav_btn {
        width: 100px;
        text-align: center;
        line-height: 32px;
        border-radius: 2px;
        margin-right: 5px;
        font-size: 14px;
        &:hover {
          cursor: pointer;
        }
      }
      .text_btn {
        color: #6782a4;
      }
      .dark_btn {
        color: white;
        background: #073d7d;
        box-shadow: 0px 5px 15px 0px rgba(25, 27, 200, 0.2);
      }
    }
  }
}
</style>
