<template>
  <div class="price">
    <div
      class="choes_bug"
      :style="{
        backgroundImage:
          'url(' + require('../assets/img/price/产品方案BG.png') + ')',
      }"
    >
      <div class="text">选择最适合您的购买方案</div>
    </div>
    <div class="version">
      <div class="item" v-for="(a, b) in price" :key="b">
        <div class="title">{{ a.title }}</div>
        <!-- <div class="_price">
          <div class="money">
            <span class="symbol">￥</span>{{ a.price
            }}<span class="company">元/年</span>
          </div>
          <div class="tip">（不限用户数量）</div>
        </div> -->
        <div class="detail">{{ a.detail }}</div>
        <div class="btn" @click="bug(b)">立即咨询</div>
      </div>
    </div>
    <div class="bg_dradual">
      <div class="area_title">版本区别</div>
      <table class="compare_table">
        <tr class="title">
          <td colspan="2">版本</td>
          <td class="bg_white">电商版</td>
          <td>企业版</td>
          <td class="bg_white">全渠道版</td>
        </tr>
        <tr>
          <td rowspan="2">基础信息许可</td>
          <td>许可店铺数</td>
          <td class="bg_white">不限</td>
          <td>不限</td>
          <td class="bg_white">不限</td>
        </tr>
        <tr>
          <td>许可操作用户数</td>
          <td class="bg_white">不限</td>
          <td>不限</td>
          <td class="bg_white">不限</td>
        </tr>
        <tr>
          <td rowspan="11">主要功能</td>
          <td>采购协同</td>
          <td class="bg_white has"></td>
          <td class="has"></td>
          <td class="bg_white has"></td>
        </tr>
        <tr>
          <td>电商业务</td>
          <td class="bg_white has"></td>
          <td class="has"></td>
          <td class="bg_white has"></td>
        </tr>
        <tr>
          <td>分销业务</td>
          <td class="bg_white no"></td>
          <td class="has"></td>
          <td class="bg_white has"></td>
        </tr>
        <tr>
          <td>仓配中心</td>
          <td class="bg_white has"></td>
          <td class="has"></td>
          <td class="bg_white has"></td>
        </tr>
        <tr>
          <td>数字门店</td>
          <td class="bg_white no"></td>
          <td class="no"></td>
          <td class="bg_white has"></td>
        </tr>
        <tr>
          <td>库存中心</td>
          <td class="bg_white has"></td>
          <td class="has"></td>
          <td class="bg_white has"></td>
        </tr>
        <tr>
          <td>账务管理</td>
          <td class="bg_white has"></td>
          <td class="has"></td>
          <td class="bg_white has"></td>
        </tr>
        <tr>
          <td>运营中心</td>
          <td class="bg_white has"></td>
          <td class="has"></td>
          <td class="bg_white has"></td>
        </tr>
        <tr>
          <td>报表分析</td>
          <td class="bg_white has"></td>
          <td class="has"></td>
          <td class="bg_white has"></td>
        </tr>
        <tr>
          <td>商品中心</td>
          <td class="bg_white has"></td>
          <td class="has"></td>
          <td class="bg_white has"></td>
        </tr>
        <tr>
          <td>全渠道业务</td>
          <td class="bg_white no"></td>
          <td class="no"></td>
          <td class="bg_white has"></td>
        </tr>
      </table>
    </div>
    <div class="question">
      <div class="area_title">常见问答</div>
      <div class="question_list">
        <div class="question_item" v-for="(a, b) in question_list" :key="b">
          <div class="q_item">
            <div class="q_icon">
              <img :src="require('../assets/img/price/Q.png')" alt="Q" />
            </div>
            <div class="content" v-html="a.q"></div>
          </div>
          <div class="a_item">
            <div class="q_icon">
              <img :src="require('../assets/img/price/A.png')" alt="A" />
            </div>
            <div class="content" v-html="a.a"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      price: [
        {
          title: '电商版',
          detail:
            '适用于小微电商企业，主要解决电商打单发货和基础进销存业务诉求',
          price: '6800',
        },
        {
          title: '企业版',
          detail:
            '适用于中大电商企业，主要解决电商打单发货、进销存、网络分销业务诉求',
          price: '19800',
        },
        {
          title: '全渠道版',
          detail:
            '适用于中大电商企业，主要解决电商打单发货、进销存、网络分销、线上线下业务诉求',
          price: '19800',
        },
        // {title:'企业中台',detail:'只为打造行业标杆',price:''},
        // {title:'定制化',detail:'',price:''},
      ],
      question_list: [
        {
          q: 'ERP系统支持哪些平台？',
          a:
            '目前主流电商平台均支持，包括天猫、淘宝、京东、拼多多、唯品会、抖音、快手等，详细可参见合作伙伴页内容。',
        },
        {
          q: 'ERP系统支持多店铺管理吗？',
          a: '系统支持多店铺管理。',
        },
        {
          q: '货物托管在第三方仓库，ERP系统支持第三方仓系统对接吗？',
          a:
            '系统支持主流的第三方仓系统对接，包括富勒WMS、通天晓WMS、胜镜iWMS、巨沃WMS、百世WMS、发网仓配等。',
        },
        {
          q: '我购买ERP软件后，后续的培训和服务收费吗？',
          a:
            '您购买ERP后，我们将安排技术支持与您远程线上对接，全程提供免费的培训与服务，保证您使用过程的顺畅',
        },
        {
          q: 'ERP系统如何保证卖家的数据安全？',
          a:
            'ERP是各平台授权的软件服务商，数据保护机制严格按照平台规范执行；我们定期会针对漏洞进行监测，严格保护卖家数据安全',
        },
      ],
    };
  },
  methods: {
    buy() {},
  },
  components: {},
};
</script>

<style scoped lang="scss">
@import '../assets/font/iconfont.css';
@media screen and (min-width: 1200px) {
  .price {
    .choes_bug {
      height: 520px;
      font-size: 46px;
      text-align: center;
      background-size: cover;
      background-position: bottom;
      color: white;
      margin-bottom: -320px;
      padding-top: 70px;
      box-sizing: border-box;
      .text {
        line-height: 60px;
      }
    }
    .version {
      display: flex;
      justify-content: center;
      .item {
        width: 250px;
        height: 300px;
        box-sizing: border-box;
        border-radius: 5px;
        box-shadow: 0 0 30px 0px #234eba33;
        margin: 0 20px;
        background-color: white;
        .title {
          text-align: center;
          font-size: 24px;
          color: #737373;
          margin-top: 30px;
        }
        .detail {
          height: 120px;
          width: 200px;
          margin: 0 auto;
          margin-top: 15px;
          padding: 0 5px;
          color: #666;
          line-height: 30px;
        }
        .tip {
          color: #bebebe;
          font-size: 14px;
          text-align: center;
        }
        .money {
          margin-top: 30px;
          text-align: center;
          color: #073d7d;
          font-size: 36px;
          .symbol {
            font-size: 28px;
          }
          .company {
            font-size: 18px;
          }
        }
        .btn {
          height: 45px;
          width: 200px;
          color: white;
          background-image: linear-gradient(to right, #ff662d, #f6a450);
          line-height: 45px;
          text-align: center;
          border-radius: 10px;
          margin: 0 auto;
          margin-top: 30px;
          &:hover {
            cursor: pointer;
          }
        }
        &.other {
          .detail {
            height: 30px;
            color: #4e4e4e;
            font-size: 20px;
            text-align: center;
          }
          .line {
            height: 1px;
            width: 220px;
            margin: 0 auto;
            background: #eaeaea;
            margin-top: 48px;
          }
          .btn {
            background: linear-gradient(to right, #2d47ff, #50b6f6);
          }
        }
      }
    }
    .area_title {
      font-size: 32px;
      line-height: 44px;
      color: #073d7d;
      text-align: center;
    }
    .bg_dradual {
      background: linear-gradient(to bottom, white, #edf2f6);
      padding: 80px 0;

      .compare_table {
        color: #073d7d;
        font-size: 18px;
        width: 830px;
        margin: 40px auto 0;
        box-shadow: 0 0 30px 0px #234eba33;
        border-collapse: collapse;
        border: 1px solid #e8e8e8;
        text-align: center;
        border-radius: 10px;
        overflow: hidden;
        td {
          border: 1px solid #e8e8e8;
          width: 60px;
          height: 80px;
          background: #f9f9f9;
          &.bg_white {
            background: white;
          }
          &.has::after {
            font-family: 'iconfont';
            content: '\eaf1';
            color: #52c41a;
            font-weight: bold;
          }
          &.no::after {
            font-family: 'iconfont';
            content: '\e8e7';
            color: #f5222d;
            font-weight: bold;
          }
        }
        .title {
          font-size: 22px;
        }
      }
    }
    .question {
      padding: 60px 0 40px;
      font-size: 18px;
      .question_list {
        width: 1000px;
        margin: 0 auto;
        margin-top: 50px;
        line-height: 30px;
        .question_item {
          .q_icon {
            width: 30px;
            text-align: center;
            border-radius: 4px;
            margin-right: 10px;
            img {
              width: 30px;
            }
          }
          .q_item {
            display: flex;
            margin-bottom: 10px;
            .content {
              flex: 1;
              color: #333;
            }
          }
          .a_item {
            margin-bottom: 40px;
            display: flex;
            align-items: flex-start;
            .content {
              flex: 1;
              color: #333;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .price {
    background: rgb(240, 242, 245);
    .choes_bug {
      background-image: none !important;
      font-size: 0.38rem;
      text-align: center;
      color: #073d7d;
      padding-top: 0.47rem;
      box-sizing: border-box;
      .text {
        line-height: 0.52rem;
      }
    }
    .version {
      .item {
        width: 6.86rem;
        height: 3.6rem;
        box-sizing: border-box;
        border-radius: 5px;
        box-shadow: 0 0 30px 0px #234eba33;
        margin: 0 auto;
        background-color: white;
        padding-top: 0.35rem;
        position: relative;
        margin-top: 0.6rem;
        .title {
          font-size: 0.32rem;
          line-height: 0.44rem;
          color: #737373;
          padding-left: 0.32rem;
        }
        .detail {
          width: 6.22rem;
          font-size: 0.28rem;
          line-height: 0.38rem;
          font-family: al-l;
          margin: 0 auto;
          margin-top: 0.7rem;
          color: #4e4e4e;
        }
        ._price {
          position: absolute;
          right: 0.3rem;
          top: 0.1rem;
        }
        .tip {
          color: #bebebe;
          font-size: 14px;
          text-align: right;
          margin-top: -0.1rem;
        }
        .money {
          text-align: right;
          color: #073d7d;
          font-size: 36px;
          .symbol {
            font-size: 28px;
            text-align: right;
          }
          .company {
            font-size: 18px;
          }
        }
        .btn {
          position: absolute;
          bottom: 0;
          left: 0;
          height: 0.68rem;
          width: 100%;
          color: white;
          background-image: linear-gradient(to right, #ff662d, #f6a450);
          line-height: 0.68rem;
          border-radius: 0 0 0.1rem 0.1rem;
          text-align: center;
          font-size: 0.28rem;
          &:hover {
            cursor: pointer;
          }
        }
        &.other {
          display: flex;
          .part {
            flex: 1;
            position: relative;
          }
          .title {
            text-align: center;
          }
          .detail {
            width: auto;
            height: 30px;
            color: #4e4e4e;
            font-size: 0.28rem;
            text-align: center;
          }
          .line {
            height: 100%px;
            width: 1px;
            margin: 0 auto;
            background: #eaeaea;
          }
          .btn {
            background: linear-gradient(to right, #2d47ff, #50b6f6);
          }
        }
      }
    }
    .area_title {
      font-size: 0.32rem;
      line-height: 0.44rem;
      color: #073d7d;
      text-align: center;
    }
    .bg_dradual {
      padding: 0.6rem 0;
      .compare_table {
        color: #073d7d;
        font-size: 0.24rem;
        width: 6.86rem;
        margin: 0.24rem auto 0;
        box-shadow: 0 0 30px 0px #234eba33;
        border-collapse: collapse;
        border: 1px solid #e8e8e8;
        text-align: center;
        border-radius: 10px;
        overflow: hidden;
        td {
          border: 1px solid #e8e8e8;
          width: 1.24rem;
          height: 0.73rem;
          background: #f9f9f9;
          &.bg_white {
            background: white;
          }
          &.has::after {
            font-family: 'iconfont';
            content: '\eaf1';
            color: #52c41a;
            font-weight: bold;
          }
          &.no::after {
            font-family: 'iconfont';
            content: '\e8e7';
            color: #f5222d;
            font-weight: bold;
          }
        }
        .title {
          font-size: 0.24rem;
        }
      }
    }
    .question {
      padding: 0 0 0.3rem;
      font-size: 0.24rem;
      .question_list {
        width: 6.86rem;
        margin: 0 auto;
        margin-top: 0.14rem;
        line-height: 0.33rem;
        .question_item {
          .q_icon {
            margin-right: 10px;
          }
          .q_item {
            display: flex;
            margin-bottom: 0.08rem;
            .q_icon {
              img {
                width: 0.34rem;
              }
            }
            .content {
              flex: 1;
              padding-top: 0.05rem;
              color: #333;
            }
          }
          .a_item {
            margin-bottom: 0.3rem;
            display: flex;
            align-items: flex-start;
            .q_icon {
              img {
                width: 0.34rem;
              }
            }
            .content {
              flex: 1;
              color: #333;
            }
          }
        }
      }
    }
  }
}
</style>
