<template>
  <div class="friend">
    <div
      class="banner"
      :style="{
        backgroundImage:
          'url(' + require('../assets/img/friend/合作伙伴BG.png') + ')',
      }"
    >
      <div class="title">携手行业优秀伙伴</div>
      <div class="detail">共建新商业生态，为客户创造更大价值</div>
    </div>
    <div class="operate">
      <div class="item">
        <div class="btn">业务合作</div>
        <div class="email">EMAIL: yushan@yushanyun.net</div>
      </div>
      <div class="item">
        <div class="btn">商务合作</div>
        <div class="email">EMAIL: yushan@yushanyun.net</div>
      </div>
    </div>
    <div class="area_title">
      支持电商平台
    </div>
    <div class="log_list">
      <div class="log_item" v-for="(a, b) in mall_list" :key="b">
        <img :src="a.img" class="item" />
      </div>
    </div>
    <div class="area_title">
      支持第三方仓储
    </div>
    <div class="flex_list">
      <img :src="a.img" class="item" v-for="(a, b) in stock_list" :key="b" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mall_list: [
        { img: require('../assets/img/friend/tmall.png'), text: 'tmall' },
        { img: require('../assets/img/friend/taobao.png'), text: 'taobao' },
        { img: require('../assets/img/friend/jingdong.png'), text: 'jingdong' },
        {
          img: require('../assets/img/friend/pinduoduo.png'),
          text: 'pinduoduo',
        },
        {
          img: require('../assets/img/friend/weipinhui.png'),
          text: 'weipinhui',
        },
        { img: require('../assets/img/friend/alibaba.png'), text: 'alibaba' },
        { img: require('../assets/img/friend/douyin.png'), text: 'douyin' },
        { img: require('../assets/img/friend/kuaishou.png'), text: 'kuaishou' },
        { img: require('../assets/img/friend/suning.png'), text: 'suning' },
        { img: require('../assets/img/friend/youzan.png'), text: 'youzan' },
        { img: require('../assets/img/friend/微盟.png'), text: 'weimeng' },
        { img: require('../assets/img/friend/dewu.png'), text: 'dewu' },
        { img: require('../assets/img/friend/LAZADA.png'), text: 'lazada' },
        { img: require('../assets/img/friend/SHOPIFY.jpg'), text: 'shopify' },
        { img: require('../assets/img/friend/aikucun.png'), text: 'aikucun' },
        {
          img: require('../assets/img/friend/小红书.png'),
          text: 'xiaohonhshu',
        },
        { img: require('../assets/img/friend/1688.png'), text: '1688' },
        { img: require('../assets/img/friend/当当.png'), text: 'dangdang' },
      ],
      stock_list: [
        { img: require('../assets/img/friend/发网.png'), text: '发网' },
        { img: require('../assets/img/friend/酷e宝.png'), text: '酷e宝' },
        { img: require('../assets/img/friend/百世.png'), text: '百世' },
        { img: require('../assets/img/friend/杉橙.png'), text: '杉橙' },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 1200px) {
  .friend {
    .banner {
      background-size: cover;
      height: 200px;
      color: rgba(255, 255, 255, 0.9);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .title {
        font-size: 32px;
        margin-bottom: 22px;
      }
      .detail {
        opacity: 0.7;
        font-size: 18px;
      }
    }
    .operate {
      margin: 0 auto;
      margin-top: 60px;
      width: 920px;
      display: flex;
      .item {
        width: 50%;
        padding-left: 50px;
        .btn {
          width: 150px;
          height: 50px;
          line-height: 50px;
          text-align: center;
          color: rgba(255, 255, 255, 0.9);
          background: #073d7d;
          box-shadow: 0 0 4px 0 #073d7d;
          font-size: 22px;
          border-radius: 25px;
        }
        .email {
          margin-top: 30px;
          line-height: 30px;
          color: #073d7d;
          font-size: 22px;
        }
      }
    }
    .area_title {
      margin-top: 80px;
      line-height: 44px;
      font-size: 32px;
      color: #073d7d;
      text-align: center;
    }
    .friend_list {
      display: flex;
      width: 1000px;
      margin: 0 auto;
      margin-top: 40px;
      .item {
        flex: 1;
        text-align: center;
        img {
          width: 125px;
          height: 42px;
          margin-top: 23px;
          object-fit: contain;
        }
        .text {
          margin-top: 5px;
          color: #333;
          font-size: 20px;
          line-height: 27px;
        }
        & + .item {
          border-left: 1px solid #f1f1f1;
        }
      }
    }
    .log_list {
      width: 1000px;
      margin: 0 auto;
      margin-top: 40px;
      .log_item {
        width: 145px;
        height: 60px;
        object-fit: contain;
        margin: 10px;
        border-radius: 5px;
        box-shadow: 0 0 6px 0 #dcdcdc;
        display: inline-block;
        padding: 5px;
        box-sizing: border-box;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    .flex_list {
      width: 1000px;
      margin: 0 auto;
      margin-top: 40px;
      display: flex;
      justify-content: space-around;
      margin-bottom: 100px;
      img {
        width: 145px;
        height: 60px;
        object-fit: contain;
        margin: 20px;
        border-radius: 5px;
        box-shadow: 0 0 6px 0 #dcdcdc;
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .friend {
    background: rgb(240, 242, 245);
    .banner {
      background-size: cover;
      height: 2rem;
      color: rgba(255, 255, 255, 0.9);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .title {
        font-size: 0.32rem;
        margin-bottom: 0.22rem;
      }
      .detail {
        opacity: 0.7;
        font-size: 0.18rem;
      }
    }
    .operate {
      margin-top: 0.4rem;
      .item {
        margin-bottom: 0.6rem;
        text-align: center;
        .btn {
          width: 1.5rem;
          height: 0.5rem;
          line-height: 0.5rem;
          text-align: center;
          color: rgba(255, 255, 255, 0.9);
          background: #073d7d;
          box-shadow: 0 0 4px 0 #073d7d;
          font-size: 0.22rem;
          border-radius: 0.25rem;
          display: inline-block;
        }
        .email {
          display: inline-block;
          margin-left: 0.24rem;
          line-height: 0.38rem;
          color: #073d7d;
          font-size: 0.28rem;
        }
      }
    }
    .area_title {
      margin-top: 0.6rem;
      line-height: 0.44rem;
      font-size: 0.32rem;
      color: #073d7d;
      text-align: center;
    }
    .friend_list {
      display: flex;
      width: 6.86rem;
      margin: 0 auto;
      margin-top: 0.47rem;
      justify-content: space-around;
      .item {
        width: 1.25rem;
        text-align: center;
        img {
          width: 100%;
          height: 0.42rem;
          object-fit: cover;
        }
        .text {
          margin-top: 0.05rem;
          color: #333;
          font-size: 0.2rem;
          line-height: 0.27rem;
        }
      }
    }
    .log_list {
      width: 6.86rem;
      margin: 0 auto;
      margin-top: 0.24rem;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      img {
        width: 1.45rem;
        height: 0.6rem;
        object-fit: contain;
        border-radius: 0.05rem;
        box-shadow: 0 0 6px 0 #dcdcdc;
        margin-bottom: 0.2rem;
      }
    }

    .flex_list {
      width: 6.86rem;
      margin: 0 auto;
      margin-top: 0.24rem;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding-bottom: 0.6rem;
      img {
        width: 1.45rem;
        height: 0.6rem;
        object-fit: contain;
        border-radius: 0.05rem;
        box-shadow: 0 0 6px 0 #dcdcdc;
        margin-bottom: 0.2rem;
      }
    }
  }
}
</style>
