<template>
  <div class="foot">
    <span>
      <span>Copyright © 2019 - 2022. All Rights Reserved. 域善科技 版权所有</span> &nbsp;<a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" style="color: #aaa;">沪ICP备19012510号-1</a>
    </span>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  methods: {

  },
  components: {

  }
}
</script>

<style scoped>

@media screen and (min-width: 1200px) {
  .foot{
    height: 66px;
    line-height: 66px;
    position: absolute;
    bottom: 0;
    left:0;
    text-align:center;
    width: 100%;
    background: rgb(15,24,33);
    color: #aaaaaa;
    font-size: 14px;
    white-space: nowrap;
  }
}
@media screen and (max-width: 1200px) {
  .foot{
    height: .8rem;
    position: absolute;
    bottom: 0;
    left:0;
    text-align:center;
    background: rgb(15,24,33);
    color: #aaaaaa;
    font-size: .14rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 0 .2rem;
  }
}
</style>
