<template>
  <div class="example">
    <div
      class="banner"
      :style="{
        backgroundImage:
          'url(' + require('../assets/img/example/案例BG.png') + ')',
      }"
    >
      <div class="title">智慧零售</div>
      <div class="detail">
        用匠心为零售企业提供更好的业务平台，赋能就业，创造更多利润
      </div>
    </div>
    <div class="area_title">商家案例</div>
    <div class="example_list">
      <div class="example_item" v-for="(a, b) in example_list" :key="b">
        <img :src="a.img" alt="a.title" />
        <div class="content">
          <div class="title">{{ a.title }}</div>
          <div class="item">
            <div class="text" v-html="a.content"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="area_title">更多客户案例</div>
    <div class="more_list">
      <div class="more_item" v-for="(a, b) in more_list" :key="b">
        <div class="log_name">
          <img :src="a.img" :alt="a.title" />
          <div class="title">{{ a.title }}</div>
        </div>
        <div class="detail">{{ a.detail }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      example_list: [
        {
          title: '箭牌家居',
          img: require('../assets/img/example/箭牌家居.png'),
          content:
            'ARROW箭牌家居集团旗下拥有ARROW箭牌、FAENZA法恩莎、ANNWA安华三大品牌；中国区域布有十大生产制造基地，在中国市场拥有10000多家销售网点，产品远销全球多个国家和地区，是国内具有实力和影响力的综合性智慧大家居企业集团。<br/><br/>域善云为其提供线上对接天猫、淘宝、京东、苏宁等平台对接打通旗下不同分销商电商业务，建立分销商和总部数据通道，打通上下游数据服务实现分销全链路管理。',
        },
        {
          title: '银泰',
          img: require('../assets/img/example/银泰百货.png'),
          content:
            '涵盖百货、购物中心、高端时尚零售等业态，是中国百货及时尚零售行业的领导者、变革者及推动者。银泰百货是第一家在港上市、业内率先向购物中心转型、第一家试水电子商务的传统零售百货公司；2014 年与阿里开启战略合作，启动银泰商业的互联网化进程，成为传统零售业与互联网融合的典范。银泰商业集团旗下的高端购物中心，成为引领时尚潮流的风向标和年轻人打卡的网红地，在国内零售业着举足轻重的影响力。目前，银泰商业集团经营和管理的百货门店及购物中心逾100 家，位居中国时尚零售业态前茅。<br/><br/>域善云为其提供线上OMS系统管理，对接天猫、淘宝、猫街等平台，同时服务银泰旗下不同分销商使用OMS业务，链接分销商和总部中台系统，打通上下游数据服务。',
        },
        {
          title: '小米淘淘',
          img: require('../assets/img/example/小米淘淘.png'),
          content:
            '小米淘淘是一家集设计，开发，生产，销售为一体的品牌，主营设计师型快时尚女装， “小米淘淘”分别在上海、江苏、浙江等主要商业中心，并逐渐向高端连锁发展。是主打日韩风、休闲服饰，产品类型以衬衫、T恤背心、雪纺、蕾丝衫、开衫外套、裤装以及裙装等多个系列的快时尚品牌；<br/><br/>域善云为其提供线上OMS系统管理，对接天猫、淘宝、猫街等平台，同时打通线下门店业务和线上电商实现全渠道一体化业务。',
        },
        {
          title: '开开',
          img: require('../assets/img/example/开开服饰.png'),
          content:
            '上海开开制衣公司是上海开开实业股份有限公司旗下的全资企业，也是唯一获得“开开”商标持有人授权经营“开开”牌衬衫、羊绒羊毛衫、西服、西裤、茄克衫、休闲服、针织内衣等服装系列产品的企业。公司拥有：百货公司、羊毛衫针织公司、衬衫总厂等6家企业，是一个集产、供、销、进出口贸易于一体的经营实体。1995年起至今，“开开”牌衬衫、羊毛衫、西服、西裤、针织内衣产品连续保持“上海名牌”称号；1997年起至今，连续保持“上海市著名商标”称号；2002年，被国家工商行政管理总局商标局认定为“中国驰名商标”。“开开”牌衬衫，在我国服装行业唯一同时荣获“中国十大名牌服装”和“中国十大名牌衬衫”。<br/><br/>域善云为其提供线上OMS系统管理，对接天猫、淘宝、猫街等平台，同时打通线下门店业务和线上电商实现全渠道一体化业务。',
        },
        {
          title: '朴坊',
          img: require('../assets/img/example/朴坊.png'),
          content:
            '朴坊 simplemill生活用品商店是一个家居设计及经营日常生活用品品牌，业务覆盖了产品的各个阶段：设计、生产、销售，专注日常用品的发展和延伸，产品范围涵盖居家、旅行、办公、服饰等。商品共计有80个类别，近6000种不同的商品。<br/><br/>域善云为其提供线上OMS系统管理，对接天猫、淘宝、猫街等平台，同时管理企业内部ERP、分销、门店业务实现全渠道一体化业务。',
        },
      ],
      more_list: [
        { img: require('../assets/img/example/图片7.png') },
        { img: require('../assets/img/example/图片8.png') },
        { img: require('../assets/img/example/图片9.png') },
        { img: require('../assets/img/example/图片10.png') },
        { img: require('../assets/img/example/图片11.png') },
        { img: require('../assets/img/example/图片12.png') },
        { img: require('../assets/img/example/图片13.png') },
        { img: require('../assets/img/example/图片14.png') },
        { img: require('../assets/img/example/图片15.png') },
        { img: require('../assets/img/example/图片16.png') },
        { img: require('../assets/img/example/图片17.png') },
        { img: require('../assets/img/example/图片18.png') },
        { img: require('../assets/img/example/图片19.png') },
        { img: require('../assets/img/example/图片20.png') },
        { img: require('../assets/img/example/图片21.png') },
        { img: require('../assets/img/example/图片22.png') },
        { img: require('../assets/img/example/图片23.png') },
      ],
    };
  },
  methods: {},
  components: {},
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 1200px) {
  .example {
    .banner {
      background-size: cover;
      height: 200px;
      color: rgba(255, 255, 255, 0.9);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .title {
        font-size: 32px;
        margin-bottom: 22px;
      }
      .detail {
        opacity: 0.7;
        font-size: 18px;
      }
    }
    .area_title {
      line-height: 44px;
      font-size: 32px;
      text-align: center;
      color: #073d7d;
      margin-top: 60px;
    }
    .example_list {
      width: 1000px;
      margin: 0 auto;
      margin-top: 40px;
      padding-bottom: 20px;
      .example_item {
        display: flex;
        border-radius: 10px;
        box-shadow: 0 0 30px 0px #234eba33;
        margin-bottom: 60px;
        img {
          width: 500px;
          border-radius: 10px;
          object-fit: contain;
          align-self: flex-start;
        }
        .content {
          margin-left: 35px;
          flex: 1;
          padding: 10px 0;
          .title {
            font-size: 24px;
            line-height: 33px;
            margin-bottom: 14px;
          }
          .item {
            display: flex;
            line-height: 22px;
            font-size: 14px;
            margin-right: 20px;
            font-family: '宋体';
            .text {
              color: #333;
            }
          }
        }
      }
      .more {
        width: 140px;
        height: 40px;
        border: 1px solid #073d7d;
        border-radius: 20px;
        margin: 0 auto;
        color: #073d7d;
        box-sizing: border-box;
        text-align: center;
        line-height: 38px;
        &:hover {
          cursor: pointer;
        }
      }
    }
    .more_list {
      width: 1000px;
      display: flex;
      flex-wrap: wrap;
      margin: 0 auto;
      margin-top: 53px;
      margin-bottom: 120px;
      .more_item {
        width: 250px;
        text-align: center;
        margin-bottom: 10px;
        img {
          width: 200px;
          height: 70px;
          object-fit: contain;
        }
        .title {
          margin-top: 5px;
          color: #333333;
          font-size: 20px;
          line-height: 27px;
        }
        .detail {
          text-align: left;
          font-size: 16px;
          margin-top: 5px;
          line-height: 22px;
        }
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .example {
    background: rgb(240, 242, 245);
    .banner {
      background-size: cover;
      height: 2rem;
      color: rgba(255, 255, 255, 0.9);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .title {
        font-size: 0.32rem;
        margin-bottom: 0.2rem;
      }
      .detail {
        opacity: 0.7;
        font-size: 0.24rem;
      }
    }
    .area_title {
      line-height: 0.44rem;
      font-size: 0.32rem;
      text-align: center;
      color: #073d7d;
      margin-top: 0.4rem;
    }
    .example_list {
      width: 6.86rem;
      margin: 0 auto;
      margin-top: 0.32rem;
      .example_item {
        box-shadow: 0 0 30px 0px #234eba33;
        margin-bottom: 0.4rem;
        background: white;
        border-radius: 0.1rem;
        img {
          width: 100%;
          height: 5.5rem;
          border-radius: 0.1rem;
        }
        .content {
          padding: 0 0.32rem;
          padding-bottom: 0.35rem;
          .title {
            font-size: 0.32rem;
            line-height: 0.44rem;
            margin-top: 0.3rem;
            margin-bottom: 0.14rem;
          }
          .item {
            display: flex;
            line-height: 0.4rem;
            font-size: 0.24rem;
            .label {
              color: #666;
            }
            .text {
              color: #333;
              flex: 1;
            }
          }
        }
      }
      .more {
        display: none;
      }
    }
    .more_list {
      width: 6.86rem;
      margin: 0 auto;
      margin-top: 0.4rem;
      padding-bottom: 0.2rem;
      display: flex;
      flex-wrap: wrap;
      .more_item {
        text-align: center;
        margin-bottom: 0.4rem;
        align-items: center;
        width: 2.2rem;
        img {
          width: 2rem;
          height: 0.7rem;
          object-fit: contain;
        }
        .title {
          color: #333333;
          font-size: 0.24rem;
          line-height: 0.33rem;
        }
        .detail {
          flex: 1;
          text-align: left;
          font-size: 0.24rem;
          margin-top: 0.05rem;
          line-height: 0.33rem;
          margin-left: 0.24rem;
        }
      }
    }
  }
}
</style>
