<template>
  <div id="app" v-title data-title="域善科技">
    <ysHeader></ysHeader>
    <router-view></router-view>
    <ysFoot></ysFoot>
  </div>
</template>

<script>
import ysFoot from './components/foot';
import ysHeader from './components/header';
export default {
  name: 'app',
  components: {
    ysHeader,
    ysFoot,
  },
};
</script>

<style>
@import './assets/icon/iconfont.css';
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}
.anchorBL {
  display: none;
}
p {
  margin: 14px 0;
}

@media screen and (min-width: 1200px) {
  #app {
    margin: 0;
    padding: 0;
    min-height: 100%;
    /* min-width: 1230px; */
    padding-bottom: 66px;
    box-sizing: border-box;
    font-size: 16px;
    position: relative;
    padding-top: 90px;
  }
}

@media screen and (max-width: 1200px) {
  #app {
    margin: 0;
    padding: 0;
    min-height: 100%;
    /* min-width: 1230px; */
    padding-bottom: 0.8rem;
    box-sizing: border-box;
    font-size: 16px;
    position: relative;
    padding-top: 1rem;
  }
}
/* 隐藏滚动条 */
body::-webkit-scrollbar {
  display: none;
}
</style>
