<template>
  <div class="about">
    <div
      class="banner"
      :style="{
        backgroundImage:
          'url(' + require('../assets/img/friend/合作伙伴BG.png') + ')',
      }"
    >
      <div class="title">成就零售企业，创造更多可能</div>
      <div class="detail">
        用匠心为零售企业提供更好用的业务平台，赋能就业，创造更多利润
      </div>
    </div>
    <div class="area_title">公司介绍</div>
    <div class="company_text">
      域善科技是专注于企业全渠道新零售业务的ERP管理软件服务商，
      <br />帮助企业快速提升信息化管理；以SAAS化零售中台应用平台，
      <br />向商家提供OMS、WMS、POS、DRP、ERP新零售管理软件应用，为商家提供优质便捷的服务，打通企业信息互联互通，实现全网数据营销。
      <br />由资深的专业技术人员、产品设计、营销运营人才<br />以丰富的企业电商管理经验，多年产品开发,以及多年的淘宝合作经验
      <br />服务过上千家的品牌企业经验，向商家提供更优质的SAAS化产品服务。
    </div>
    <div class="area_title">发展历程</div>
    <div class="dev_list">
      <div class="dev_item" v-for="(a, b) in dev_list" :key="b">
        <div class="year">{{ a.year }}</div>
        <div class="content" v-html="a.content"></div>
      </div>
      <div class="extra">推出电子商务ERP整体解决方案</div>
    </div>
    <div class="area_title">联系我们</div>
    <div class="concat_list">
      <div class="concat_item" v-for="(a, b) in concat_list" :key="b">
        <img :src="a.img" :alt="a.title" />
        <div class="title">{{ a.title }}</div>
        <div class="content">{{ a.content }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dev_list: [
        {
          year: '2021',
          content:
            '开拓跨境业务，服务商家海外拓展<br/>推进企业品牌O2O整合业务实现全渠道业务闭环<br/>建立生态伙伴联营机制，打通同行服务商系统之间闭环，为零售企业内部各系统之间提供专业一体化解决方案',
        },
        {
          year: '2020',
          content:
            '推出系统英文版<br/>打通跨境平台对接<br/>构建企业数据通道平台支持分离式部署',
        },
        {
          year: '2019',
          content:
            '推动企业O2O全渠道业务<br/>为企业实现线上线下业务一体化运营<br/>落地数字中台管理系统打通企业内部数据壁垒',
        },
        {
          year: '2018',
          content:
            '推出品牌企业网络分销系统和仓库发货WMS系统<br/>构建企业中台管理架构，完善SaaS在线服务体系<br/>域善云零售管理系统迅速获得电商品牌企业信赖',
        },
        {
          year: '2017',
          content:
            '公司成立<br/>专注电商品牌企业信息化，构建SaaS化技术架构和运维体系，推出电商专业管理软件；',
        },
      ],
      concat_list: [
        {
          img: require('../assets/img/about/地址.png'),
          title: '地址',
          content: '上海市徐汇区桂中园1号楼101室',
        },
        {
          img: require('../assets/img/about/邮箱.png'),
          title: '邮箱',
          content: 'yushan@yushanyun.net',
        },
        {
          img: require('../assets/img/about/电话.png'),
          title: '电话',
          content: '18930371050',
        },
      ],
    };
  },
  methods: {},
  mounted() {},
  components: {},
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 1200px) {
  .about {
    .banner {
      background-size: cover;
      height: 200px;
      color: rgba(255, 255, 255, 0.9);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .title {
        font-size: 32px;
        margin-bottom: 22px;
      }
      .detail {
        opacity: 0.7;
        font-size: 18px;
      }
    }
    .area_title {
      margin-top: 60px;
      line-height: 44px;
      font-size: 32px;
      color: #073d7d;
      text-align: center;
    }
    .company_text {
      width: 1000px;
      margin: 0 auto;
      color: #666;
      line-height: 28px;
      font-size: 16px;
      margin-top: 30px;
    }
    .dev_list {
      margin: 0 auto;
      margin-top: 50px;
      width: 1000px;
      .dev_item {
        display: flex;
        padding-top: 17px;
        height: 145px;
        box-sizing: border-box;
        background: rgba(7, 61, 125, 0.05);
        position: relative;
        .year {
          width: 300px;
          text-align: right;
          font-size: 42px;
          color: #073d7d;
          line-height: 58px;
          &::after {
            content: '';
            width: 10px;
            height: 10px;
            display: inline-block;
            border-radius: 5px;
            background: #073d7d;
            margin-bottom: 10px;
            margin-left: 10px;
          }
        }
        .content {
          margin-left: 20px;
          flex: 1;
          padding-top: 10px;
          font-size: 18px;
          line-height: 25px;
          color: #333;
        }
        & + .dev_item::before {
          content: '';
          display: block;
          width: 0;
          border-left: 1px dashed #bababa;
          z-index: -1;
          height: 100%;
          position: absolute;
          left: 295px;
          bottom: 100px;
        }
        &:first-child {
          background: rgba(255, 69, 54, 0.05);
          .year {
            color: #ff4536;
            &::after {
              background: #ff4536;
            }
          }
        }
      }
      .extra {
        background: rgba(7, 61, 125, 0.05);
        color: rgba(7, 61, 125, 0.2);
        font-size: 36px;
        line-height: 50px;
        padding-top: 10px;
        padding-left: 10px;
        box-sizing: border-box;
      }
    }
    .concat_list {
      margin: 0 auto;
      margin-top: 40px;
      width: 800px;
      box-shadow: 0 0 30px 0 rgba(35, 77, 186, 0.2);
      display: flex;
      padding: 30px 0;
      margin-bottom: 120px;
      .concat_item {
        flex: 1;
        text-align: center;
        color: #073d7d;
        img {
          height: 55px;
          width: 60px;
        }
        .title {
          font-size: 22px;
          margin-top: 15px;
        }
        .content {
          font-size: 14px;
          margin-top: 15px;
        }
        & + .concat_item {
          border-left: 1px solid #e8e8e8;
        }
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .about {
    background: rgb(240, 242, 245);
    padding-bottom: 0.5rem;
    .banner {
      background-size: cover;
      height: 2rem;
      color: rgba(255, 255, 255, 0.9);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .title {
        font-size: 0.32rem;
        margin-bottom: 0.22rem;
      }
      .detail {
        opacity: 0.7;
        font-size: 0.18rem;
      }
    }
    .area_title {
      margin-top: 0.4rem;
      line-height: 0.44rem;
      font-size: 0.32rem;
      color: #073d7d;
      text-align: center;
    }
    .company_text {
      width: 6.86rem;
      margin: 0 auto;
      color: #666;
      line-height: 0.38rem;
      font-size: 0.28rem;
      margin-top: 0.24rem;
    }
    .dev_list {
      margin-top: 0.4rem;
      .dev_item {
        display: flex;
        padding-top: 0.17rem;
        height: 1.45rem;
        box-sizing: border-box;
        background: rgba(7, 61, 125, 0.05);
        position: relative;
        .year {
          width: 1.9rem;
          text-align: right;
          font-size: 0.42rem;
          color: #073d7d;
          line-height: 0.58rem;
          position: relative;
          z-index: 1;
          &::after {
            content: '';
            width: 0.1rem;
            height: 0.1rem;
            display: inline-block;
            border-radius: 0.05rem;
            background: #073d7d;
            margin-bottom: 0.1rem;
            margin-left: 0.1rem;
          }
        }
        .content {
          margin-left: 0.2rem;
          flex: 1;
          padding-top: 0.1rem;
          font-size: 0.24rem;
          line-height: 0.32rem;
          color: #333;
        }
        & + .dev_item::before {
          content: '';
          display: block;
          width: 0;
          border-left: 1px dashed #bababa;
          height: 100%;
          position: absolute;
          left: 1.85rem;
          bottom: 1rem;
        }
        &:first-child {
          background: rgba(255, 69, 54, 0.05);
          .year {
            color: #ff4536;
            &::after {
              background: #ff4536;
            }
          }
        }
      }
      .extra {
        background: rgba(7, 61, 125, 0.05);
        color: rgba(7, 61, 125, 0.2);
        font-size: 0.24rem;
        line-height: 0.33rem;
        padding-top: 0.3rem;
        padding-left: 0.29rem;
        padding-bottom: 0.26rem;
        box-sizing: border-box;
      }
    }
    .concat_list {
      width: 6.86rem;
      margin: 0 auto;
      margin-top: 0.24rem;
      box-shadow: 0 0 30px 0 rgba(35, 77, 186, 0.2);
      background: white;
      padding: 0 0.5rem;
      box-sizing: border-box;
      border-radius: 0.1rem;
      .concat_item {
        height: 1.2rem;
        display: flex;
        align-items: center;
        text-align: left;
        color: #073d7d;
        font-size: 0.28rem;
        img {
          height: 0.55rem;
          width: 0.6rem;
        }
        .title {
          margin-left: 0.4rem;
          display: inline-block;
        }
        .content {
          margin-left: 0.2rem;
          display: inline-block;
        }
      }
    }
  }
}
</style>
