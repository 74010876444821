<template>
  <div class="main">
    <div class="carousel">
      <!-- <el-carousel trigger="click" :loop="false" arrow="always" indicator-position="none">
        <el-carousel-item > -->
      <div class="swiper_content">
        <!-- <div class="title">
          专业的全渠道
          <div class="bold">零售中台</div>
          <div class="exp">赋能企业数字化升级，打通全渠道业务闭环</div>
          <div class="btn">即刻体验</div>
        </div> -->
        <img :src="serve" alt="img" />
      </div>
      <!-- </el-carousel-item>
      </el-carousel> -->
    </div>
    <div class="mobile_bg">
      <img :src="require('../assets/img/index/mobilebg.jpg')" alt="" />
    </div>
    <div class="main_content_bg bg1">
      <img
        class="bg_img"
        :src="require('../assets/img/index/BG.png')"
        alt="核心功能"
      />
      <div class="main_title">
        <h3>核心功能</h3>
        <p>
          <span class="grey">帮助商家实现业务闭环，助力业绩增长</span
          ><span class="red"></span>
        </p>
      </div>
      <div class="main_content">
        <div
          class="content_item shadow"
          v-for="(item, i) in service_list"
          :key="i"
        >
          <div class="item_icon">
            <img :src="item.img" :alt="item.title" />
          </div>
          <div class="item_text">
            <div class="title">{{ item.title }}</div>
            <div class="text">{{ item.text }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="main_content_bg bg2">
      <div class="main_title">
        <h3>解决痛点</h3>
        <p>
          <span class="grey">实现数据闭环，高效协同</span
          ><span class="red"></span>
        </p>
      </div>
      <div class="main_content">
        <div class="content_item" v-for="(item, i) in resolve_list" :key="i">
          <div class="item_icon">
            <img :src="item.img" :alt="item.title" />
          </div>
          <div class="item_text">
            <div class="title">{{ item.title }}</div>
            <div class="text">{{ item.text }}</div>
          </div>
        </div>
        <img
          class="bg_img"
          :src="require('../assets/img/index/ss.png')"
          alt="解决痛点"
        />
      </div>
    </div>
    <div class="main_content_bg bg3">
      <img
        class="bg_img"
        :src="require('../assets/img/index/Background.png')"
        alt="核心功能"
      />
      <div class="main_title">
        <h3>选择我们</h3>
        <p>
          <span class="grey">赋能零售企业数字化升级</span
          ><span class="red"></span>
        </p>
      </div>
      <div class="main_content">
        <div class="content_item" v-for="(item, i) in chose_list" :key="i">
          <div class="item_text">
            <div class="title">{{ item.title }}</div>
            <div class="text" v-for="(text, index) in item.text" :key="index">
              {{ text }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import serve from '../assets/img/index/pcbg.png';
// import storage from "../assets/img/storage.jpg"
export default {
  data() {
    return {
      serve: serve,
      list: [
        { text: '域善科技 为你的每一笔订单负责', img: serve },
        // ,{text:"全渠道统一对接 快速处理订单、商品、库存问题",img:storage}
      ],
      service_list: [
        {
          img: require('../assets/img/index/电商业务.png'),
          title: '电商业务',
          text:
            '集成70+电商平台，支持多仓多店库存共享机制；支持订单智能审单、合单、拆单等',
        },
        {
          img: require('../assets/img/index/分销业务.png'),
          title: '分销业务',
          text: '一件代发、传统经销、天猫供销多场景支持，助力分销业绩不断提高',
        },
        {
          img: require('../assets/img/index/数字门店.png'),
          title: '数字门店',
          text: '打通企业现有门店系统实现全渠道场景',
        },
        {
          img: require('../assets/img/index/采购协同.png'),
          title: '采购协同',
          text: '正常补货、供应商代销、供应商代发，让协同不再难',
        },
        {
          img: require('../assets/img/index/仓储管理.png'),
          title: '仓储管理',
          text: '精准库存管理，电商、线下、JIT多模式发货支持',
        },
        {
          img: require('../assets/img/index/财务管理.png'),
          title: '账务管理',
          text: '采购应付、经销应收、成本管理、电商对账，账目准确清晰',
        },
        {
          img: require('../assets/img/index/多维数据.png'),
          title: '多维数据报表',
          text: '整合全渠道各类数据，告别不同业务信息孤岛',
        },
        {
          img: require('../assets/img/index/数据管理.png'),
          title: '主数据管理',
          text: '帮助企业进行基础数据治理，降低维护成本',
        },
      ],
      resolve_list: [
        {
          img: require('../assets/img/index/管理者.png'),
          title: '管理者',
          text: '打通线上线下，优化流程，把控风险，决策支持',
        },
        {
          img: require('../assets/img/index/运营.png'),
          title: '运营',
          text: '数据驱动，提升精细化运营能力与财务、供应链高效协同',
        },
        {
          img: require('../assets/img/index/财务.png'),
          title: '财务',
          text: '数据及时准确，提升财务工作效率告别繁琐对账',
        },
      ],
      chose_list: [
        {
          icon: 'icon-gongneng',
          title: '专业',
          text: ['十年零售行业经验累积', '功能完全贴合零售企业'],
        },
        {
          icon: 'icon-fast',
          title: '安全',
          text: [
            '阿里云、聚石塔、云鼎、多多云合作伙伴',
            '安全机制符合各平台要求',
          ],
        },
        {
          icon: 'icon-jiandan',
          title: '易用',
          text: [
            '以事找人功能设计理念',
            '界面简洁易用，上手快',
            '支持独立部署，专属域名',
          ],
        },
        {
          icon: 'icon-chengbendi',
          title: '服务',
          text: ['24小时提供支持', '全程免费培训与服务支持'],
        },
        // ,{icon:"icon-fuwu",title:"服务好",text:"24*7小时在线客服，咨询相应时间不超过3分钟"}
      ],
    };
  },
  methods: {},
  components: {},
};
</script>

<style lang="scss" scoped>
p {
  margin: 0;
}
@media screen and (min-width: 1200px) {
  .main {
    .swiper_content {
      // height: 750px;
      background: white;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        color: #ff4536;
        font-size: 54px;
        width: 330px;
        margin: 0 auto;
        text-align: center;
        .bold {
          font-size: 80px;
          color: rgb(8, 60, 126);
        }
        .exp {
          font-size: 16px;
          line-height: 40px;
          background: #073d7d;
          color: rgba(255, 255, 255, 0.8);
          margin-top: 20px;
        }
        .btn {
          margin-top: 60px;
          font-size: 14px;
          width: 124px;
          text-align: center;
          line-height: 40px;
          background: linear-gradient(to right, #ff4536, #ff675b);
          border-radius: 4px;
          color: rgba(255, 255, 255, 0.8);
          &:hover {
            cursor: pointer;
          }
        }
      }
      img {
        width: 100%;
      }
    }
    .carousel {
      position: relative;
      overflow: hidden;
      .el-carousel__container {
        height: 750px;
      }
      .el-carousel__item {
        h3 {
          color: white;
          font-size: 34px;
          line-height: 430px;
          margin: 0;
          text-align: center;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: 0 -160px;
          span {
            line-height: 1;
            padding: 10px;
            border-radius: 10px;
            background: rgba(0, 0, 0, 0.1);
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
          }
        }
      }
    }
    .mobile_bg {
      display: none;
    }
    .main_content_bg {
      padding-top: 60px;
      .main_title {
        text-align: center;
        color: #073d7d;
        h3 {
          font-weight: 500;
          font-size: 32px;
          margin: 0;
        }
        .grey {
          font-size: 18px;
          // margin-right: 20px;
        }
        .red {
          font-size: 20px;
          color: rgb(228, 57, 42);
        }
      }
      .main_content {
        display: flex;
        justify-content: space-around;
        width: 1200px;
        margin: 0 auto;
        padding-bottom: 60px;
        flex-wrap: wrap;
        .content_item {
          background: white;
          text-align: center;
          padding-top: 24px;
          width: 250px;
          height: 250px;
          box-sizing: border-box;
          margin-top: 60px;
          &.shadow {
            box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.05);
            border-radius: 10px;
          }
          .item_icon {
            width: 100px;
            display: inline-block;
            margin-bottom: 15px;
            .iconfont {
              font-size: 58px;
              line-height: 70px;
              color: red;
            }
          }
          .item_text {
            width: 180px;
            margin: 0 auto;
            color: #073d7d;
            .title {
              font-size: 20px;
              margin-bottom: 10px;
            }
            .text {
              font-size: 16px;
              color: rgb(112, 112, 112);
            }
          }
        }
      }
      &.bg1 {
        padding-top: 100px;
        position: relative;
        .bg_img {
          width: 100%;
          height: 700px;
          position: absolute;
          left: 0;
          top: 0;
          z-index: -1;
        }
        .main_title {
          color: rgba(255, 255, 255, 0.8);
          .grey {
            color: rgba(255, 255, 255, 0.8);
          }
        }
      }
      &.bg2 {
        .main_content {
          position: relative;
          width: 1100px;
          justify-content: space-between;
          margin-top: 60px;
          .bg_img {
            width: 837px;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: 0;
            z-index: -1;
          }
          .content_item {
            padding: 10px;
            box-sizing: border-box;
            width: 500px;
            height: 160px;
            border-radius: 160px;
            margin-top: 85px;
            margin-bottom: 0;
            box-shadow: 0 0 30px 0px #234eba33;
            display: flex;
            &:first-child {
              margin: 0 300px;
            }

            .item_icon {
              width: 140px;
              height: 140px;
              margin-bottom: 0px;
              img {
                width: 100%;
                height: 100%;
                border-radius: 100%;
              }
            }
            .item_text {
              margin-left: 30px;
              text-align: left;
              flex: 1;
              .title {
                font-size: 20px;
                margin-top: 20px;
                margin-bottom: 10px;
              }
              .text {
                line-height: 30px;
                font-size: 18px;
                color: rgb(112, 112, 112);
              }
            }
          }
        }
      }
      &.bg3 {
        margin-top: 60px;
        position: relative;
        .bg_img {
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          z-index: -1;
        }
        .main_title {
          color: rgba(255, 255, 255, 0.8);
          .grey {
            color: rgba(255, 255, 255, 0.8);
          }
        }
        .main_content {
          .content_item {
            background: transparent;
            .item_text {
              color: rgba(255, 255, 255, 0.8);
              .title {
                font-size: 32px;
                margin-bottom: 10px;
              }
              .text {
                font-size: 16px;
                line-height: 30px;
                color: rgba(255, 255, 255, 0.8);
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .main {
    background: rgb(240, 242, 245);
    .swiper_content {
      height: 100%;
      background: white;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        color: #ff4536;
        font-size: 54px;
        width: 330px;
        margin: 0 auto;
        text-align: center;
        .bold {
          font-size: 80px;
          color: rgb(8, 60, 126);
        }
        .exp {
          font-size: 16px;
          line-height: 40px;
          background: #073d7d;
          color: rgba(255, 255, 255, 0.8);
          margin-top: 20px;
        }
        .btn {
          margin-top: 60px;
          font-size: 14px;
          width: 124px;
          text-align: center;
          line-height: 40px;
          background: linear-gradient(to right, #ff4536, #ff675b);
          border-radius: 4px;
          color: rgba(255, 255, 255, 0.8);
          &:hover {
            cursor: pointer;
          }
        }
      }
      img {
        display: none;
        height: 570px;
        margin-right: -90px;
      }
    }
    .mobile_bg {
      height: 3.4rem;
      width: 100%;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    .carousel {
      display: none;
    }
    .main_content_bg {
      padding-top: 0.4rem;
      .main_title {
        text-align: center;
        color: #073d7d;
        h3 {
          font-weight: 500;
          font-size: 0.32rem;
          line-height: 0.44rem;
          margin: 0;
        }
        .grey {
          font-size: 0.24rem;
          line-height: 0.34rem;
          margin-top: 0.14rem;
          // margin-right: 20px;
        }
        .red {
          font-size: 20px;
          color: rgb(228, 57, 42);
        }
      }
      .main_content {
        display: flex;
        justify-content: space-between;
        margin: 0 0.32rem;
        flex-wrap: wrap;
        .content_item {
          background: white;
          text-align: center;
          padding-top: 24px;
          width: 3.3rem;
          height: 3.3rem;
          box-sizing: border-box;
          margin-top: 0.3rem;
          &.shadow {
            box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.05);
            border-radius: 10px;
          }
          .item_icon {
            width: 0.6rem;
            display: inline-block;
            margin-bottom: 0.05rem;
            img {
              width: 0.6rem;
            }
          }
          .item_text {
            width: 2.74rem;
            margin: 0 auto;
            color: #073d7d;
            .title {
              font-size: 0.32rem;
              margin-bottom: 0.09rem;
            }
            .text {
              font-size: 0.24rem;
              text-align: left;
            }
          }
        }
      }
      &.bg1 {
        position: relative;
        .bg_img {
          width: 100%;
          height: 700px;
          position: absolute;
          left: 0;
          top: 0;
          z-index: -1;
          display: none;
        }
      }
      &.bg2 {
        .main_content {
          position: relative;
          justify-content: space-between;
          margin-top: 0.3rem;
          .bg_img {
            display: none;
          }
          .content_item {
            padding: 0.1rem;
            box-sizing: border-box;
            width: 6rem;
            margin: 0 auto;
            height: 1.6rem;
            border-radius: 1.6rem;
            margin-bottom: 0.4rem;
            box-shadow: 0 0 30px 0px #234eba33;
            display: flex;

            .item_icon {
              width: 1.4rem;
              height: 1.4rem;
              margin-bottom: 0px;
              img {
                width: 100%;
                height: 100%;
                border-radius: 100%;
              }
            }
            .item_text {
              margin-left: 0.3rem;
              text-align: left;
              flex: 1;
              .title {
                font-size: 0.28rem;
                margin-top: 0.16rem;
                margin-bottom: 0.06rem;
              }
              .text {
                line-height: 0.28rem;
                font-size: 0.24rem;
                color: #6782a4;
              }
            }
          }
        }
      }
      &.bg3 {
        margin-top: 0;
        position: relative;
        .bg_img {
          width: 100%;
          height: 5.2rem;
          position: absolute;
          left: 0;
          bottom: 0;
          display: block;
        }
        .main_title {
          position: relative;
          z-index: 1;
        }
        .main_content {
          position: relative;
          z-index: 1;
          .content_item {
            background: transparent;
            height: 2.4rem;
            padding-top: 0.3rem;
            .item_text {
              color: rgba(255, 255, 255, 0.8);
              .title {
                font-size: 0.32rem;
                margin-bottom: 0.18rem;
              }
              .text {
                width: 2.6rem;
                font-size: 0.24rem;
                line-height: 0.34rem;
                margin: 0 auto;
                color: rgba(255, 255, 255, 0.8);
              }
            }
          }
        }
      }
    }
  }
}
</style>
