import Vue from 'vue';
import Router from 'vue-router';

import Index from '@/components/index.vue';
import About from '@/components/about.vue';
import Price from '@/components/price.vue';
import Example from '@/components/example.vue';
import Friend from '@/components/friend.vue';

Vue.use(Router);

let router = new Router({
  routes: [
    { path: '/index', component: Index },
    { path: '/about', component: About },
    { path: '/price', component: Price },
    { path: '/example', component: Example },
    { path: '/friend', component: Friend },
    { path: '/', redirect: '/index' },
  ],
});

router.afterEach(() => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
});

export default router;
